import {
    GeneralResponse,
    LoginForm,
    RedirectResponse,
    VerificationForm
} from "./IGeneral";
import { FormInputValidityResult } from "./Interfaces";

import {
    FormValid,
    hideElement,
    showElement,
    toggleLoader
} from "./general-utils";

const mainContainer = document.getElementById('main') as HTMLElement;

//Buttons
const loginButton = document.getElementById("login-button") as HTMLButtonElement;
const authenticateButton = document.getElementById("authenticate-button") as HTMLButtonElement;

//Forms
const loginForm = document.getElementById("login-form") as HTMLFormElement;
const authForm = document.getElementById("auth-form") as HTMLFormElement;

//Message Elements
const loginResponseMessage = document.getElementById("login-response-message") as HTMLElement;
const authResponseMessage = document.getElementById("auth-response-message") as HTMLElement;

document.addEventListener("DOMContentLoaded", () => {
    if (loginButton)
        loginButton.addEventListener("click", handleLoginButtonClick);

    if (authenticateButton)
        authenticateButton.addEventListener("click", handleAuthenticateButtonClick);
})

async function handleLoginButtonClick(event: Event): Promise<void> {
    toggleLoader('login-loader');
    const formValid: boolean = validateForm(loginForm);

    if (formValid) {
        const loginFormData: LoginForm = {
            UserName: (document.getElementById('username') as HTMLInputElement).value,
            Password: (document.getElementById('login-password') as HTMLInputElement).value,
            RememberMe: (document.getElementById('remember-me') as HTMLInputElement).checked
        };

        await login(loginFormData);
    }

    toggleLoader('login-loader');
}

async function handleAuthenticateButtonClick(event: Event): Promise<void> {
    toggleLoader('login-loader');

    const formValid: boolean = validateForm(authForm);

    if (formValid) {
        const authFormData: VerificationForm = {
            TotpCode: (document.getElementById('auth-code') as HTMLInputElement).value
        }

        await authenticate(authFormData);
    }

    toggleLoader('login-loader');
}

function validateForm(form: HTMLFormElement): boolean {
    let valid: boolean = true;
    const inputs:NodeListOf<HTMLInputElement> = form.querySelectorAll('input');

    inputs.forEach(input => {
        const formGroup:HTMLElement = input.closest(".form-group");

        if (formGroup) {
            const errorElement: HTMLElement = formGroup.querySelector("span");
            if (errorElement) {

                if (!input.validity.valid) {
                    valid = false;
                    errorElement.textContent = input.validationMessage;
                } else {
                    errorElement.textContent = "";
                }
            }
        }
    })

    return valid;
}



async function login(loginform: LoginForm): Promise<void> {
    try {
        loginButton.disabled = true;
        const response = await fetch("/Account/Login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(loginform)
        })

        if (response.ok) {
            const data: RedirectResponse = await response.json();
            if (data) {
                localStorage.setItem('user', JSON.stringify(data.userData));
                if (data.require2FA) {
                    hideElement(loginForm);
                    showElement(authForm);
                } else {
                    window.location.href = data.redirectUrl;
                }
            }
        } else {
            const generalResponse: GeneralResponse = await response.json();
            loginResponseMessage.textContent = generalResponse.message;
        }
    }
    catch (e) {
    }
    finally
    {
        loginButton.disabled = false;
    }
}

async function authenticate(verificationForm: VerificationForm) : Promise<void>{
    try {
        authenticateButton.disabled = true;
        const response = await fetch("/Account/VerifyTotp", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
            },

            body: JSON.stringify(verificationForm)
        })

        if (response.ok) {
            const data: RedirectResponse = await response.json();
            window.location.href = data.redirectUrl;
        } else {
            const generalResponse: GeneralResponse = await response.json();
            authResponseMessage.textContent = generalResponse.message;
        }
    }
    catch (e) {
    }
    finally
    {
        authenticateButton.disabled = false;
    }
}