import {
    DropDownElement,
    FormInputValidityResult
} from "./Interfaces";

import {
    getBillings
} from "./billing";

import {
    getCountries
} from "./company";

import {
    getCustomers
} from "./customer";

import {
    getEntryTypes
} from "./entryType";

import {
    lists
} from "./global";

import {
    getRates
} from "./rate";

import {
    getServicePlanTypes
} from "./servicePlanType";

import {
    getPriorities,
    getStatuses
} from "./ticket";

import {
    getUsers
} from "./user";


//---Validation for inputs ---//
export function FormValid(form: HTMLFormElement): boolean {
    let formValid = true;

    const inputs = form.querySelectorAll("input") as NodeListOf<HTMLInputElement>;
    const selects = form.querySelectorAll("select") as NodeListOf<HTMLSelectElement>;
    const textAreas = form.querySelectorAll("textarea") as NodeListOf<HTMLTextAreaElement>;
    const multiSelects = form.querySelectorAll('[data-select-type="multi"]') as NodeListOf<HTMLSelectElement>;

    inputs.forEach(input => {
        let result: FormInputValidityResult = ValidateInput(input);
        const formGroup = input.parentElement.closest(".form-group");
        if (formGroup) {
            const errorMessage = formGroup.querySelector("span");
            errorMessage.textContent = result.validationMessage;
        }

        if (!result.valid) {
            formValid = false;
        }
    })

    selects.forEach(select => {
        let result: FormInputValidityResult = ValidateInput(select);
        const formGroup = select.parentElement.closest(".form-group");
        if (formGroup) {
            const errorMessage = formGroup.querySelector("span");
            errorMessage.textContent = result.validationMessage;
        }

        if (!result.valid) {
            formValid = false;
        }
    })

    multiSelects.forEach(multiSelect => {
        if (!multiSelect.required) return;
        const multiSelectContainerItems = multiSelect.closest(".container") as HTMLElement;
        if (!multiSelectContainerItems) return;
        const length = multiSelectContainerItems.children.length;
        let result: FormInputValidityResult = validateMultiDropdown(length);
        const formGroup = multiSelect.closest(".form-group");
        if (formGroup) {
            const errorMessage = formGroup.querySelector("span");
            errorMessage.textContent = result.validationMessage;
        }

        if (!result.valid) {
            formValid = false;
        }
    })

    textAreas.forEach(textArea => {
        let result: FormInputValidityResult = ValidateInput(textArea);
        const formGroup = textArea.parentElement.closest(".form-group");
        if (formGroup) {
            const errorMessage = formGroup.querySelector("span");
            errorMessage.textContent = result.validationMessage;
        }

        if (!result.valid) {
            formValid = false;
        }
    })

    return formValid;
}

export function MultiDropdownValid(multiDropDown: HTMLSelectElement, trackList: string[]): boolean {
    let dropDownValid = true;

    let result: FormInputValidityResult = ValidateMultiDropDown(trackList);

    const formGroup = multiDropDown.parentElement.closest(".form-group");
    if (formGroup) {
        const errorMessage = formGroup.querySelector("span");
        errorMessage.textContent = result.validationMessage;
    }

    if (!result.valid) {
        dropDownValid = false;
    }

    return dropDownValid;
}

export function ValidateTimeElements(startDate: HTMLInputElement, startTime: HTMLInputElement, endDate: HTMLInputElement, endTime: HTMLInputElement): boolean {
    let spanMessage: HTMLSpanElement = startTime.closest(".form-group").querySelector("span");

    if (endDate.value < startDate.value && endTime.value <= startTime.value) {
        spanMessage.textContent = "Invalid datetime selection";
        return false;
    } else if (endDate.value >= startDate.value && endTime.value <= startTime.value) {
        spanMessage.textContent = "Invalid datetime selection";
        return false;
    } else if (endDate.value >= startDate.value && endTime.value > startTime.value) {
        spanMessage.textContent = "";
        return true;
    }
}

function ValidateInput(input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): FormInputValidityResult {
    let status : FormInputValidityResult = {
        valid: true,
        validationMessage: ""
    };

    if (input.type !== "hidden" && input.required) {

        if (!input.validity.valid) {
            status.valid = false;
            status.validationMessage = input.validationMessage;
        } else {
            status.validationMessage = "";
        }

        if (input.type === "tel") {
            const phonePattern = /^\d/
            if (!input.validity.valid) {
                status.valid = false;
                status.validationMessage = input.validationMessage;
            }else if (!phonePattern.test(input.value)) {
                status.valid = false;
                status.validationMessage = "The phone number does not follow the correct pattern (000 000 000)";
            } else {
                status.validationMessage = "";
            }
        }

        if (input.type === "password") {
            if (input.value.length < 16) {
                status.valid = false;
                status.validationMessage = "The password must be at least 16 characters long";
            }
            else if (!/\d/.test(input.value)) {
                status.valid = false;
                status.validationMessage = "The password must contain at least one digit";
            } else if (!/[a-z]/.test(input.value)) {
                status.valid = false;
                status.validationMessage = "The password must contain at least one lowercase letter";
            } else if (!/[A-Z]/.test(input.value)) {
                status.valid = false;
                status.validationMessage = "The password must containe at least one uppercase letter";
            } else if (!/\W/.test(input.value)) {
                status.valid = false;
                status.validationMessage = "The password must contain at least one special character";
            } else {
                status.validationMessage = "";
            }
        }

        if (input.type === "select-one") {
            if (!input.validity.valid || input.value === "null") {
                status.valid = false;
                status.validationMessage = input.validationMessage || "Please select a value";
            } else {
                status.validationMessage = "";
            }
        }
    }

    return status;
}

function ValidateMultiDropDown(trackList: string[]) : FormInputValidityResult{
    let status: FormInputValidityResult = {
        valid: true,
        validationMessage: ""
    };

    if (trackList.length === 0) {
        status.valid = false;
        status.validationMessage = "Please select at least one element";
        return status;
    } else {
        status.valid = true;
        status.validationMessage = "";
        return status
    }
}

function validateMultiDropdown(containerLength: number): FormInputValidityResult {
    let status: FormInputValidityResult = {
        valid: true,
        validationMessage: ""
    };

    if (containerLength <= 0) {
        status.valid = false;
        status.validationMessage = "Please select at least one element";
        return status;
    } else {
        status.valid = true;
        status.validationMessage = "";
        return status
    }
}



//---Custom List Selections---//
export function PopulateMultiDropdown(elements: DropDownElement[], dropdown: HTMLSelectElement) {
    elements.forEach(element => {
        const newOption = document.createElement("option");
        newOption.value = element.Value;
        newOption.textContent = element.Description;
        dropdown.appendChild(newOption);
    })
}

export function PopulateMultiDropdownContainer(dropdown: HTMLSelectElement, listContainer: HTMLElement, trackList: string[]) {
    trackList.forEach(id => {
        const option: HTMLOptionElement = Array.from(dropdown.options).find(o => o.value == id);

        if (option && option.value) {
            let optionDiv = document.createElement("p");
            optionDiv.classList.add("multilist-item");
            optionDiv.id = id;
            optionDiv.innerHTML = option.innerText + " <i class='fa-solid fa-x'></i>";
            listContainer.appendChild(optionDiv);
            dropdown.removeChild(option);

            const removeIcon = optionDiv.querySelector(".fa-x");
            removeIcon.addEventListener("click", () => {
                RemoveListDropdownItem(removeIcon.parentElement, dropdown, listContainer);
                trackList.splice(trackList.indexOf(optionDiv.id), 1);
            })
        }
    })
}

export function ResetMultiDropdown(dropdown: HTMLElement, listContainer: HTMLElement, trackList: string[]) {
    trackList = [];

    listContainer.querySelectorAll("p").forEach(x => {
        const newOption = document.createElement("option");
        newOption.value = x.id;
        newOption.textContent = x.textContent;
        dropdown.appendChild(newOption);
        listContainer.removeChild(x);
    })
}

export function MultiDropdown(dropdown: HTMLSelectElement, listContainer: HTMLElement, trackList: string[]) {
    const option : HTMLOptionElement = Array.from(dropdown.options).find(o => o.value == dropdown.value);

    if (option.value) {
        let optionDiv = document.createElement("p");
        optionDiv.classList.add("multilist-item");
        optionDiv.id = dropdown.value;
        optionDiv.innerHTML = option.innerText + " <i class='fa-solid fa-x'></i>";
        listContainer.appendChild(optionDiv);
        dropdown.removeChild(option);
        if (trackList.indexOf(optionDiv.id) === -1)
            trackList.push(optionDiv.id);

        const removeIcon = optionDiv.querySelector(".fa-x");
        removeIcon.addEventListener("click", () => {
            RemoveListDropdownItem(removeIcon.parentElement, dropdown, listContainer);
            trackList.splice(trackList.indexOf(optionDiv.id), 1);
        })
    }
}

export function ClearDropDown(dropdown: HTMLSelectElement) {
    if (dropdown) {
        dropdown.querySelectorAll("option:not(:first-child)").forEach(x => {
            x.remove();
        });
    }
}

function RemoveListDropdownItem(target: HTMLElement, dropdown: HTMLSelectElement, listContainer: HTMLElement){
    const newOption = document.createElement("option");
    newOption.value = target.id;
    newOption.textContent = target.textContent;
    dropdown.appendChild(newOption);
    listContainer.removeChild(target);
}

export function populatePageDropdowns(dropdowns: NodeListOf<HTMLElement>) {
    try {
        const userDropdowns = [
            "customer-assigned-users",
            "new-customer-assigned-users",
            "dialog-time-entry-user-id",
            "ticket-assigned-user-ids",
            "user-dropdown",
            "entry-user-id",
            "note-user-id",
            "schedule-assigned-user-ids",
        ];

        const customerDropdowns = [
            "ticket-customer-id",
            "customer-dropdown",
            "filter-customer-id",
            "schedule-customerId",
        ];

        const statusDropdowns = [
            "ticket-status-id",
            "status-dropdown",
            "filter-status-id"
        ];

        const servicePlanTypeDropdowns = [
            "service-plan-types"
        ];

        const billingPeriodDropdowns = [
            "billing-plan-periods"
        ]

        const rateDropdowns = [
            "rates"
        ]

        const entryTypeDropdowns = [
            "entry-type-id"
        ];

        const priorityDropdowns = [
            "ticket-priority-id",
            "filter-priority-id"
        ]; 

        const countryDropdowns = [
            "countries",
        ]

        dropdowns.forEach(async (dropdown: HTMLSelectElement) => {
            const loader = dropdown.parentElement.querySelector(".progress-loader") as HTMLElement;
            let options: DropDownElement[] = [];

            if (dropdown.dataset.contentLoaded === "true") return;
            if (!loader) return;

            if (countryDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.countries.length === 0)
                    lists.countries = await getCountries();

                lists.countries.forEach(country => {
                    const option: DropDownElement = {
                        Value: country.countryId,
                        Description: country.countryName
                    };
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (rateDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add('active');
                if (lists.rates.length == 0)
                    lists.rates = await getRates();

                lists.rates.forEach(rate => {
                    const option: DropDownElement = {
                        Value: rate.id,
                        Description: rate.name + " ("+ rate.price +")"
                    }

                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove('active');
                return;
            }

            if (billingPeriodDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.billingPlans.length == 0)
                    lists.billingPlans = await getBillings();

                lists.billingPlans.forEach(billing => {
                    const option: DropDownElement = {
                        Value: billing.billingId,
                        Description: billing.billingDescription
                    };

                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (priorityDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.priorities.length == 0)
                    lists.priorities = await getPriorities();
                
                lists.priorities.forEach(priority => {
                    const option: DropDownElement = {
                        Value: priority.id,
                        Description: priority.description,
                    }
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (userDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.users.length == 0)
                    lists.users = await getUsers();

                lists.users.forEach(user => {
                    const option: DropDownElement = {
                        Value: user.id,
                        Description: user.firstname + " " + user.lastname + " (" + user.email + ") ",
                    };
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (customerDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.customers.length == 0)
                    lists.customers = await getCustomers();

                lists.customers.forEach(customer => {
                    const option: DropDownElement = {
                        Value: customer.customerId,
                        Description: customer.customerName
                    }
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (servicePlanTypeDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if(lists.servicePlanTypes.length == 0)
                    lists.servicePlanTypes = await getServicePlanTypes();

                lists.servicePlanTypes.forEach(servicePlanType => {
                    const option: DropDownElement = {
                        Value: servicePlanType.servicePlanTypeId,
                        Description: servicePlanType.servicePlanTypeName
                    };
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (statusDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.statuses.length == 0)
                    lists.statuses = await getStatuses();

                lists.statuses.forEach(status => {
                    const option: DropDownElement = {
                        Value: status.id,
                        Description: status.description
                    };
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }

            if (entryTypeDropdowns.indexOf(dropdown.id) > -1) {
                loader.classList.add("active");
                if (lists.entryTypes.length === 0)
                    lists.entryTypes = await getEntryTypes();

                lists.entryTypes.forEach(entryType => {
                    const option: DropDownElement = {
                        Value: entryType.id,
                        Description: entryType.description
                    };
                    options.push(option);
                })

                PopulateMultiDropdown(options, dropdown);
                dropdown.dataset.contentLoaded = "true";
                loader.classList.remove("active");
                return;
            }
        })
    } catch (e) {
        console.log(e);
    }
}



//-------Custom Dialogs-------//

export function ResetDialogForms(dialog: HTMLElement) {
    const dialogForms = dialog.querySelectorAll("form");
    dialogForms.forEach(form => {
        form.reset();
    })
}

export function HideDialog(dialogId: string) {
    const dialog = document.getElementById(dialogId);
    if (!dialog) return;

    const overlay = dialog.querySelector(".overlay") as HTMLElement;

    if (overlay.classList.contains("show"))
        overlay.classList.remove("show");

    dialog.classList.toggle("show");
}

export function ShowDialog(dialogId: string) {
    const dialog = document.getElementById(dialogId);
    if (!dialog) return;

    const overlay = dialog.querySelector(".overlay") as HTMLElement;

    if (!overlay.classList.contains("show"))
        overlay.classList.add("show");

    dialog.classList.toggle("show");
}

export function toggleDialog(dialogId: string) {
    const dialog: HTMLElement = document.getElementById(dialogId);
    if (!dialog) return;

    const overlay = dialog.querySelector(".overlay") as HTMLElement;
    if (!overlay) return;

    dialog.classList.toggle("show");
    overlay.classList.toggle("show");
}


//-------Custom Flyouts-------//
export function toggleFlyout(flyoutId: string) {
    const flyout = document.getElementById(flyoutId) as HTMLElement;
    const pageOverlay = document.getElementById('page-overlay') as HTMLElement;
    if (!flyout || !pageOverlay) return;

    flyout.classList.toggle("active");

    if (flyout.classList.contains("active")) {
        lists.activeFlyouts.push(flyoutId);
    }
    else {
        const index = lists.activeFlyouts.indexOf(flyoutId);
        if(index > -1)
            lists.activeFlyouts.splice(index, 1);
    }

    if (lists.activeFlyouts.length > 0) {
        pageOverlay.classList.add("show");
    }
    else {
        pageOverlay.classList.remove("show");
    }
}


//------Right Click Menu-------//
export function toggleRightClick(e: MouseEvent, target: HTMLElement) {
    e.preventDefault();
    target.classList.add("active");

    const menuWidth = target.offsetWidth;
    const menuHeight = target.offsetHeight;

    let posX = e.pageX;
    let posY = e.pageY;

    if (posX + menuWidth > window.innerWidth) posX -= menuWidth;
    if (posY + menuHeight > window.innerHeight) posY -= menuHeight;

    target.style.left = `${posX}px`;
    target.style.top = `${posY}px`;
}

export function hideRightClickMenu(target: HTMLElement) {
    if (target.classList.contains('active')) {
        target.classList.remove('active');
    }
}

//----------General-----------//
export function showButton(button: HTMLButtonElement) {
    if (button.classList.contains("d-none")) {
        button.classList.remove("d-none");
    }
}

export function hideButton(button: HTMLButtonElement) {
    if (!button.classList.contains("d-none")) {
        button.classList.add("d-none");
    }
}

export function showElement(element: HTMLElement) {
    if(element)
        if (element.classList.contains("d-none"))
            element.classList.remove("d-none");
}

export function hideElement(element: HTMLElement) {
    if (element)
        if (!element.classList.contains("d-none"))
            element.classList.add("d-none");
}

export function hasAncestorWithClass(target, className) {
    while (target) {
        if (target.classList && target.classList.contains(className)) {
            return true;
        }
        target = target.parentElement;
    }

    return false;
}

export function findAncestorWithId(element, selector) {
    while (element && element !== document.body) {
        if (element.id && element.classList.contains(selector)) return element;
        element = element.parentElement;
    }
    return null;
}

export function GetCookie(cookieName) {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${cookieName}=`)) {
            return cookie.substring(cookieName.length + 1);
        }
    }

    return null;
}

export function ShowItemSquentially(element: HTMLElement) {
    element.style.transform = 'scale(1)';
    element.style.opacity = '1';
}

export function ShowItemsSequentially(startingIndex: number, containerName: string, contentClassName: string) {
    const container = document.querySelector(containerName);
    const items = container.querySelectorAll(contentClassName);

    if (startingIndex < items.length) {
        (items[startingIndex] as HTMLElement).classList.add("appear");

        setTimeout(() => {
            ShowItemsSequentially(startingIndex + 1, containerName, contentClassName);
        }, 50);
    }
}

export function elementFadeInOut(element: HTMLElement, timeout: number) {
    element.classList.add("d-block", "fade-in");

    setTimeout(() => {
        element.classList.remove("fade-in");
        element.classList.add("fade-out");

        setTimeout(() => {
            element.classList.remove("d-block", "fade-out");
        }, 300);
    }, timeout)
}

export function toggleOverlay(overlayId: string) {
    const overlay = document.getElementById(overlayId) as HTMLElement;
    if (!overlay) return;

    overlay.classList.toggle("show");
}

export function toggleLoader(loader: string | HTMLElement) {
    let loaderElement: HTMLElement;
    if (typeof (loader) == "string") {
        loaderElement = document.getElementById(loader) as HTMLElement;
        if (!loader) return;
    } else {
        loaderElement = loader;
    }

    loaderElement.classList.toggle("active");
}

export function removeFadeElement(element: HTMLElement) {
    if (!element) return;
    element.style.transition = "opacity 0.5s linear";
    element.style.opacity = "0";

    setTimeout(() => {
        element.remove();
    }, 550)
}

export function formatDate(dateString: string): string {
    const date = new Date(dateString);

    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return `${year}/${month}/${day}`;
}

export function formatTime(dateString: string): string {
    const date = new Date(dateString);
    const time = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});

    return time;
}

export function formatDateLocalDateTime(date: Date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();

    let monthFormatted = month < 10 ? '0' + month : month;
    let dayFormatted = day < 10 ? '0' + day : day;
    let hourFormatted = hour < 10 ? '0' + hour : hour;
    let minuteFormatted = minute < 10 ? '0' + minute : minute;

    return `${year}-${monthFormatted}-${dayFormatted}T${hourFormatted}:${minuteFormatted}`;
}

export function toggleSelectedItemInList(listParent: HTMLUListElement, target: HTMLElement) {
    const listItems = listParent.querySelectorAll('li');
    if (listItems) {
        listItems.forEach(x => x.classList.remove('selected'));
        target.classList.add('selected');
    }
}