import { get } from "jquery";
import { FilterTicketsForm } from "./IFilter";
import { UserTicketGroup } from "./IReport";
import { TicketData, TicketFilters, TicketForm } from "./ITicket";
import {
    AttachmentData,
    TicketRequestData,
    TimeEntryData,
    TimeEntryForm,
    TimerStateViewModel,
    TravelTimeData,
    TravelTimeForm
} from "./Interfaces";
import { elementFadeInOut } from "./general-utils";
import { StatusData } from "./IStatus";
import { PriorityData } from "./IPriority";

const errorAlert = document.getElementById("error-alert") as HTMLElement;

export class Ticket {
    constructor() {

    }
}



export async function getTickets(count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetTickets?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let data: TicketData[] = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getUserTickets(userId: string, count: number, lastIndex): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetUserTickets?userId=${userId}&count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let data: TicketData[] = await response.json();
            return data;
        } else {
            return null;
        }
    }
    catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}


export async function getCustomerTickets(customerId: string, count: number, lastIndex): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetCustomerTickets?customerId=${customerId}&count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let data: TicketData[] = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getTicketInfo(ticketId: string): Promise<TicketData | null> {
    try {
        const response = await fetch(`/Ticket/GetTicket?ticketId=${ticketId}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }
    }
    catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getTicketRequestInfo(ticketRequestId: string): Promise<TicketRequestData | null> {
    try {
        const response = await fetch(`/Ticket/GetTicketRequest?ticketRequestId=${ticketRequestId}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const data: TicketRequestData = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getTicketTimeEntries(ticketId: string): Promise<TimeEntryData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetTimeEntries?ticketId=${ticketId}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const data: TimeEntryData[] = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getTicketTravelTimes(ticketId: string): Promise<TravelTimeData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetTravelTimes?ticketId=${ticketId}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const data: TravelTimeData[] = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getTicketRequests(count: number, lastIndex: number): Promise<TicketRequestData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetTicketRequests?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let ticketRequestData: TicketRequestData[] | null = await response.json();
            return ticketRequestData;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getRecycledTickets(count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetRecycledTickets?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let recycledTickets: TicketData[] | null = await response.json();
            return recycledTickets;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getOpenTickets(count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetOpenTickets?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let tickets: TicketData[] | null = await response.json();
            return tickets;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getCompletedTickets(count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetCompletedTickets?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let tickets: TicketData[] | null = await response.json();
            return tickets;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getMyOpenTickets(count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetMyOpenTickets?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let tickets: TicketData[] | null = await response.json();
            return tickets;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getMyCompletedTickets(count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetMyCompletedTickets?count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let tickets: TicketData[] | null = await response.json();
            return tickets;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function getMergableTickets(ticketId: string | null, count: number, lastIndex: number): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/GetMergableTickets?ticketId=${ticketId}&count=${count}&lastIndex=${lastIndex}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            let tickets: TicketData[] | null = await response.json();
            return tickets;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function saveTicket(ticketFormData: TicketForm): Promise<TicketData | null> {
    try {
        const response = await fetch("/Ticket/SaveTicket", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            },

            body: JSON.stringify(ticketFormData)
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        return null;
    }
}

export async function addTicket(ticket: TicketForm): Promise<TicketData | null> {
    try {
        const response = await fetch("/Ticket/CreateNewTicket", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            },

            body: JSON.stringify(ticket)
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function updateTicket(ticket: TicketForm): Promise<TicketData | null> {
    try {
        const response = await fetch(`/Ticket/UpdateTicket`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            },

            body: JSON.stringify(ticket)
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function deleteTicket(ticketId: string): Promise<boolean> {
    try {
        const response = await fetch(`/Ticket/DeleteTicket?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            },
        })

        if (response.ok) {
            return true
        } else {
            return false;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return false;
    }
}

export async function deleteTicketRequest(ticketRequestId: string): Promise<boolean> {
    try {
        const response = await fetch(`/Ticket/DeleteTicketRequest?ticketRequestId=${ticketRequestId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            },
        })

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return false;
    }
}

export async function recycleTicket(ticketId: string): Promise<boolean> {
    try {
        const response = await fetch(`/Ticket/RecycleTicket?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            },
        })

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return false;
    }
}

export async function startTimer(ticketId: string): Promise<void> {
    try {
        await fetch(`/Ticket/StartTimer?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            }
        })
    }
    catch (e) {
        elementFadeInOut(errorAlert, 3000);
    }
}

export async function pauseTimer(ticketId: string): Promise<void> {
    try {
        await fetch(`/Ticket/PauseTimer?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            }
        })
    }
    catch (e) {
        elementFadeInOut(errorAlert, 3000);
    }
}

export async function stopTimer(ticketId: string): Promise<void> {
    try {
        await fetch(`/Ticket/StopTimer?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            }
        })
    }
    catch (e) {
        elementFadeInOut(errorAlert, 3000);
    }
}

export async function addTimeEntry(timeEntry: TimeEntryForm): Promise<TimeEntryData | null> {
    try {
        const response = await fetch(`/Ticket/AddTimeEntry`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            },

            body: JSON.stringify(timeEntry.model)
        })

        if (response.ok) {
            const data: TimeEntryData = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function addTravelTime(travelTime: TravelTimeForm): Promise<TravelTimeData | null> {
    try {
        const response = await fetch(`/Ticket/AddTravelTime`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            },

            body: JSON.stringify(travelTime.model)
        })

        if (response.ok) {
            const data: TravelTimeData = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function markAsComplete(ticketId: string): Promise<TicketData | null> {
    try {
        const response = await fetch(`/Ticket/MarkTicketAsComplete?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            }
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function filterTickets(ticketFilters : FilterTicketsForm): Promise<TicketData[] | null> {
    try {
        const response = await fetch(`/Ticket/FilterTickets`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            },

            body: JSON.stringify(ticketFilters)
        })

        if (response.ok) {
            const data: TicketData[] = await response.json()
            return data;
        } else {
            return null;
        }

    } catch (e) {
        errorAlert.textContent = e;
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function filterTicketsReport(ticketFilters: TicketFilters): Promise<UserTicketGroup[] | null> {
    try {
        const response = await fetch(`/Ticket/FilterTicketsReport`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json"
            },

            body: JSON.stringify(ticketFilters)
        })

        if (response.ok) {
            const data: UserTicketGroup[] = await response.json()
            return data;
        } else {
            return null;
        }

    } catch (e) {
        errorAlert.textContent = e;
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function mergeTicketWithTicketRequest(ticketId: string, ticketRequestId): Promise<TicketData | null> {
    try {
        const response = await fetch(`/Ticket/MergeTicketWithTicketRequest?ticketId=${ticketId}&ticketRequestId=${ticketRequestId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            },
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function mergeTicketWithTicket(ticketId: string, mergingTicketId: string): Promise<TicketData | null> {
    try {
        const response = await fetch(`/Ticket/MergeTicketWithTicket?ticketId=${ticketId}&mergingTicketId=${mergingTicketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketVerificationToken']") as HTMLInputElement).value
            },
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function fetchEmailImages(ticketRequestId: string): Promise<string | null> {
    try {
        const response = await fetch(`/Ticket/FetchEmailImages?ticketRequestId=${ticketRequestId}`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.ok) {
            const data = await response.json();
            return data.body;
        } else {
            return null;
        }
    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return null;
    }
}

export async function clearRecycleBin(): Promise<boolean> {
    try {
        const response = await fetch(`/Ticket/ClearRecycleBin`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            }
        })

        if (response.ok) {
            return true;
        } else {
            return false;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return false;
    }
}

export async function restoreTicket(ticketId: string): Promise<boolean> {
    try {
        const response = await fetch(`/Ticket/RestoreTicket?ticketId=${ticketId}`, {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                "RequestVerificationToken": (document.querySelector("input[name='__TicketRequestVerificationToken']") as HTMLInputElement).value
            }
        })

        if (response.ok) {
            return true;
        } else {
            return false;
        }

    } catch (e) {
        elementFadeInOut(errorAlert, 3000);
        return false;
    }
}

export async function getTicketTimerState(ticketId: string): Promise<TimerStateViewModel | null> {
    try {
        const response = await fetch(`/Timer/GetTicketTimerState?ticketId=${ticketId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })

        if (response.ok) {
            const data: TimerStateViewModel = await response.json();
            return data;
        } else {
            return null;
        }

    } catch (e) {
        return null;
    }
}

export async function getAttachment(ticketId: string, attachmentId): Promise<void> {
    try {
        const response = await fetch(`/Ticket/DownloadTicketAttachment?ticketId=${ticketId}&attachmentId=${attachmentId}`, {
            method: 'GET',
        })

        if (response.ok) {
            const blob = await response.blob();

            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'no_attachment_name';

            if (contentDisposition) {
                const match = contentDisposition.match(/filename="([^"]+)"/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        return null;
    } catch (e) {
        return null;
    }
}

export async function getStatuses(): Promise<null | StatusData[]> {
    try {
        const response = await fetch(`/Ticket/GetStatuses`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const data: StatusData[] = await response.json();
            return data;
        }

        return null;

    } catch (e) {
        return null;
    }
}

export async function getPriorities(): Promise<null | PriorityData[]> {
    try {
        const response = await fetch(`/Ticket/GetPriorities`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })

        if (response.ok) {
            const data: PriorityData[] = await response.json();
            return data;
        }

        return null;

    } catch (e) {
        return null;
    }
}

export async function searchTicket(refNumber: number, signal: AbortSignal): Promise<TicketData | null> {
    try {
        const response = await fetch(`/Ticket/SearchTicket?referenceNumber=${refNumber}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal
        })

        if (response.ok) {
            const data: TicketData = await response.json();
            return data;
        }

        return null;

    } catch (e) {
        return null;
    }
}