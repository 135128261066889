import { BillingData } from "./IBilling";
import { CustomerData } from "./ICustomer";
import { EntryType } from "./IEntry";
import { PriorityData } from "./IPriority";
import { RateData } from "./IRate";
import { ServicePlanType } from "./IServicePlan";
import { StatusData } from "./IStatus";
import {
    UserData
} from "./IUser";
import { CountryData } from "./Interfaces";

export let timers = {};

export function clearTimers() {
    timers = {};
}

export const errorAlert = document.getElementById('error-alert') as HTMLElement;

export let flags = {
    contentLoaded: false,
}

export const lists = {
    billingPlans: [] as BillingData[],
    countries: [] as CountryData[],
    customers: [] as CustomerData[],
    entryTypes: [] as EntryType[],
    priorities: [] as PriorityData[],
    rates: [] as RateData[],
    servicePlanTypes: [] as ServicePlanType[],
    statuses: [] as StatusData[],
    users: [] as UserData[],

    activeFlyouts: [] as string[]
}